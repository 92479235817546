<template>
  <div class="content">
    <van-list
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index" style="overflow-y: scroll">
        <van-row>
          <van-cell-group inset>
            <van-cell :title="item.des" :value="item.updatedAt | formatDate " :label="getValue(item.value,item.type,item.unit)" />
          </van-cell-group>
        </van-row>
      </div>
    </van-list>
  </div>
</template>

<script>
import { List } from "vant";
export default {
  data() {
    return {
      type: -1,
      typeid: -1,
      showIndex: -1,
      deviceName: "",
      list: [],
      loading: false,
      finished: false,
      error: false,
      page: 1,
      curData: "",
      yanganurl:
        "http://www.rkonfly.cn/upload/file/content/2022/05/62831321666bd.jpg",
    };
  },
   filters: {
    formatDate: function (value) {
      if (typeof value == "string" && value.indexOf("T") < 0) {
        value = value.replace(/-/g, "/");
      }
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.showIndex = this.$route.query.index;

    if (this.type == 1) {
      this.deviceName = "燃气设备";
      this.typeid = 2;
    } else if (this.type == 2) {
      this.deviceName = "烟感设备";
      this.typeid = 3;
    } else {
      this.deviceName = "空开设备";
      this.typeid = 1;
    }
  },
  methods: {
    getValue(value,type,unit){  
        if(this.type == 1 || this.type == 2){
            return value
        }else{
          if(value == 0 && type == 205){
              return '关'
          }
          if(value == 1 && type == 205){
              return '开'
          }
          return value+unit;
        }
    },
    onLoad() {
      this.http
        .get("/mp/sg/devices", {
          params: {
            companyId: localStorage.getItem("companyId"),
            deviceClassifyType: this.typeid,
            page: this.page,
            size: 10,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.loading = false;
            this.list = this.list.concat(
              data.data.list[this.showIndex].latestDataList
            );
            this.finished = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style>
.content {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}
.cardItem {
  background-color: #ffffff;
  width: 100%;
  height: 200px;
}
.img {
  margin-top: 16px;
  margin-left: 10px;
}
</style>